@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    --main-color: #DB0B00; /* Rosso Corsa */
    --secondary-color-1: #FFB4E1; /* Light Hot Pink */
    --secondary-color-2: #EDBE4C; /* Maximum Yellow Red */
    --secondary-color-3: #F0F2E4; /* Alabaster */
    --secondary-color-4: #FFD9F0; /* Pink Lace */
    --font-color: #000000; /* Default black */
    --card-background: white;
}

/* Base Styles */
body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
    background-color: var(--secondary-color-3);
    color: var(--font-color);
    line-height: 1.6;
}

/* Header and Navigation Styles */
header {
    background-color: var(--main-color);
    color: white;
    padding: 1rem;
    text-align: center;
    font-family: 'Bukhari Script', cursive;
    font-size: 3rem;
}

/* PrimeReact Menubar Custom Styles */
.p-menubar {
    padding: 0.5rem 1rem;
    border: none !important;
    background-color: var(--main-color) !important;
}

.p-menubar .p-menuitem-link {
    color: white !important;
    padding: 0.75rem 1.25rem;
    transition: all 0.3s ease !important;
}

.p-menubar .p-menuitem-link:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.p-menubar .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menuitem-link .p-menuitem-text {
    color: white !important;
}

.p-menubar .p-menuitem-link:hover .p-menuitem-text,
.p-menubar .p-menuitem-link:hover .p-menuitem-icon {
    color: var(--secondary-color-2) !important;
}

/* BirriaRoulette Enhanced Styles */
.roulette-container {
    min-height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary-color-3);
    padding: 2rem;
}

.wheel-container {
    width: 100%;
    max-width: min(90vh, 800px); /* Increased from 600px */
    aspect-ratio: 1;
    margin: 2rem auto;
    position: relative;
    transition: all 0.3s ease;
}

.wheel-outer-ring {
    position: absolute;
    inset: -16px; /* Increased from -12px */
    border-radius: 50%;
    background: conic-gradient(
        from 0deg,
        var(--main-color),
        var(--secondary-color-2),
        var(--main-color),
        var(--secondary-color-1),
        var(--main-color)
    );
    animation: glow 2s infinite;
    box-shadow: 0 0 30px rgba(219, 11, 0, 0.3);
}

.wheel-inner {
    position: absolute;
    inset: 0;
    border-radius: 50%;
    background: var(--secondary-color-3);
    border: 10px solid var(--main-color); /* Increased from 8px */
}

.prize-section {
    position: relative;
    border-left: 4px solid var(--secondary-color-2);
}

.prize-content {
    background: linear-gradient(
        135deg,
        var(--secondary-color-4),
        var(--secondary-color-1)
    );
}

.prize-text {
    font-family: 'Open Sans Bold', sans-serif;
    font-size: 1.1rem; /* Increased size */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    white-space: nowrap;
    color: white;
    background: rgba(0, 0, 0, 0.4);
    padding: 4px 8px;
    border-radius: 12px;
}

.wheel-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px; /* Increased from 80px */
    height: 120px; /* Increased from 80px */
    border-radius: 50%;
    background: radial-gradient(
        circle,
        var(--secondary-color-2) 0%,
        var(--main-color) 70%
    );
    border: 8px solid var(--main-color);
    box-shadow: 
        0 0 30px rgba(219, 11, 0, 0.3),
        inset 0 0 20px rgba(0, 0, 0, 0.3);
}

.wheel-pointer {
    position: absolute;
    top: -30px; /* Adjusted for larger size */
    left: 50%;
    transform: translateX(-50%);
    width: 60px; /* Increased from 40px */
    height: 60px; /* Increased from 40px */
    background: linear-gradient(
        135deg,
        var(--secondary-color-2),
        var(--main-color)
    );
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    filter: drop-shadow(0 6px 6px rgba(0, 0, 0, 0.3));
}

.prize-icon {
    width: 32px; /* Increased size */
    height: 32px;
    padding: 6px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    margin-bottom: 8px;
}

.control-panel {
    margin-top: 2rem;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.spin-button {
    width: 240px; /* Increased from 200px */
    height: 70px; /* Increased from 60px */
    font-family: 'Open Sans Bold', sans-serif;
    font-size: 1.5rem; /* Increased from 1.25rem */
    color: white;
    background: linear-gradient(
        45deg,
        var(--main-color),
        var(--secondary-color-1)
    );
    border: none;
    border-radius: 35px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(219, 11, 0, 0.3);
}

.spin-button:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(219, 11, 0, 0.4);
    background: linear-gradient(
        45deg,
        var(--secondary-color-1),
        var(--main-color)
    );
}

.spin-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

/* Animations */
@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

@keyframes glow {
    0%, 100% {
        box-shadow: 
            0 0 20px var(--main-color),
            0 0 40px var(--secondary-color-1);
    }
    50% {
        box-shadow: 
            0 0 40px var(--main-color),
            0 0 60px var(--secondary-color-1);
    }
}

/* Modal Styles */
.modal-content {
    background: linear-gradient(
        to bottom,
        var(--secondary-color-2),
        var(--secondary-color-1)
    );
    border: 6px solid var(--main-color);
    border-radius: 20px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
}

/* Responsive Design */
@media (max-width: 1024px) {
    .wheel-container {
        max-width: min(85vh, 700px);
    }
}

@media (max-width: 768px) {
    .wheel-container {
        max-width: min(80vh, 500px);
    }
    
    .wheel-center {
        width: 90px;
        height: 90px;
    }
    
    .wheel-pointer {
        width: 50px;
        height: 50px;
    }
    
    .prize-text {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .wheel-container {
        max-width: 90vw;
    }
    
    .prize-text {
        font-size: 0.8rem;
    }
    
    .spin-button {
        width: 200px;
        height: 60px;
        font-size: 1.2rem;
    }
    .prize-icon {
        color: black;
        font-size: 24px; /* Adjust size as needed */
        padding: 6px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 50%;
        margin-bottom: 8px;
    }
    
}